<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th
            colspan="7"
            class="text-right"
          >
            <v-btn
              @click="newBudget"
              color="primary"
              rounded
            >
              Novo orçamento
              <v-icon right>fas fa-plus</v-icon>
            </v-btn>
          </th>
        </tr>
        <tr>
          <th
            v-for="(item, idx) in headers"
            :key="idx"
            :class="`text-${item.align}`"
          >
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in items"
          :key="idx"
          @dblclick="
            $router.push({ name: 'budgets-edit', params: { id: item.id } })
          "
        >
          <td>{{ item.number }}</td>
          <td>{{ item.seller }}</td>
          <td class="text-center">
            <v-chip
              :color="getBudgetColor(item.budget_status)"
              class="text-overline white--text"
            >
              {{ checkBudgetStatus(item.budget_status) }}
            </v-chip>
          </td>
          <td>{{ item.company }}</td>
          <td>
            <ul class="pa-0">
              <li>{{ item.client_company_name }}</li>
              <li>{{ item.client_trading_name }}</li>
              <li>{{ item.contact_cel + ' | ' + item.contact_phone }}</li>
              <li>{{ item.contact_email }}</li>
            </ul>
          </td>
          <td class="text-right">{{ item.created_at }}</td>
          <td class="text-right">{{ item.sended_at }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { filter } from '@/main.js'
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: 'N°', value: 'number' },
      { text: 'Vendedor', value: 'seller' },
      { text: 'Status', value: 'budget_status', align: 'center' },
      { text: 'Empresa', value: 'company' },
      { text: 'Contato', value: 'contact' },
      { text: 'Data de criação', value: 'created_at', align: 'end' },
      { text: 'Data de envio', value: 'sended_at', align: 'end' },
    ],
    items: [],
    dataTable: {
      title: 'Orçamentos',
      new: 'budgets-new',
      edit: 'budgets-edit',
      context: 'budgets-context',
      filter: 'budgets-filter',
      massAction: 'budgets-mass-action',
      headerButtons: {
        filter: true,
        new: true,
      },
      isLoading: false,
    },
  }),
  methods: {
    getBudgets() {
      this.$api
        .post('budget/client', { client_id: this.client.id })
        .then((res) => {
          this.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    getFilteredBudgets(obj) {
      obj['client_id'] = this.client.id
      this.$api
        .post('budget/filter', obj)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    checkBudgetStatus(status) {
      switch (status) {
        case 90:
          return 'Aguardando'
        case 1:
          return 'Enviado'
        case 2:
          return 'Aprovado'
        case 3:
          return 'Reprovado'
        case 4:
          return 'Abrir CRM'
        default:
          break
      }
    },
    getBudgetColor(status) {
      switch (status) {
        case 90:
          return 'blue-grey'
        case 1:
          return 'cyan'
        case 2:
          return 'success'
        case 3:
          return 'error'
        case 4:
          return 'warning'
      }
    },
    newBudget() {
      this.$store.commit('setNewBudgetClientAndSellerId', {
        clientId: this.client.id,
        sellerId: this.client.seller_id,
      })
      this.$router.push({ name: 'budgets-new' })
    },
  },
  created: function () {
    this.getBudgets()

    filter.$once('action', (obj) => {
      if (obj) this.getFilteredBudgets(obj)
      else this.getBudgets()
    })
  },
}
</script>
